<form [formGroup]="globalSearch" (ngSubmit)="onSubmit()" class="global-search">
  <mat-form-field appearance="outline" fxFlexFill floatLabel="never">
    <input matInput placeholder="Search" autocomplete="off" />
    <span matSuffix class="flex">
      <button
        type="button"
        (click)="globalSearchService.toggleAdvancedSearch()"
        mat-icon-button
        aria-label="Advanced Search"
      >
        <mat-icon>manage_search</mat-icon>
      </button>
      <button type="submit" mat-icon-button aria-label="Search">
        <mat-icon>search</mat-icon>
      </button>
    </span>
  </mat-form-field>
</form>
