import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ThemeConstant } from '@modules/app/constants/theme.constant';
import { ReducerInterface } from '@modules/app/state/theme/interface';
import { ModuleInterface } from '@modules/app/state/interface';
import { UPDATE } from '@modules/app/state/theme/actions';

@Component({
  selector: 'app-theme',
  templateUrl: './theme.component.html',
  styleUrls: ['./theme.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ThemeComponent implements OnInit {
  public themeObservable: Observable<ReducerInterface>;

  public themes = ThemeConstant;

  constructor(private store: Store<ModuleInterface>) {
    this.themeObservable = store.select(states => {
      return states.app.theme;
    });
  }

  ngOnInit(): void {}

  updateTheme(theme: ReducerInterface): void {
    this.store.dispatch({
      type: UPDATE,
      results: {
        name: theme.name,
        href: theme.href
      }
    });
  }
}
