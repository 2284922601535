import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { UserMenuService } from '@modules/app/services/user-menu/user-menu.service';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserMenuComponent implements OnInit {
  constructor(public userMenuService: UserMenuService) {}

  ngOnInit(): void {}
}
