import { Injectable } from '@angular/core';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { ModuleInterface } from '@modules/app/state/interface';
import { STORE } from '@modules/app/state/responsive/actions';
import { filter, map } from 'rxjs/operators';
/**
 * The Responsive Service
 * @stores responsive information on load of application, as well as any further updates
 */
@Injectable({
  providedIn: 'root'
})
export class ResponsiveService {
  /**
   * Responsive subscription
   * Used to update media queries as user changes their window size
   */
  public responsive: Subscription = new Subscription();
  /**
   * The "constructor" function
   * @params MediaObserver
   * @params Store
   */
  constructor(
    public mediaObjserver: MediaObserver,
    private store: Store<ModuleInterface>
  ) {}
  /**
   * The "getConfiguration" function
   * @returns void
   */
  getConfiguration(): void {
    this.responsive = this.mediaObjserver
      .asObservable()
      .pipe(
        filter((changes: MediaChange[]) => changes.length > 0),
        map((changes: MediaChange[]) => changes[0])
      )
      .subscribe((change: MediaChange) => {
        const responsiveUpdate = {
          media_query: change.mediaQuery,
          alias: change.mqAlias,
          suffix: change.suffix
        };
        this.store.dispatch({
          type: STORE,
          result: responsiveUpdate
        });
      });
  }
}
