import { STORE_MAIN, STORE_MAIN_EXPAND_SHRINK, STORE_USER } from './actions';

import { ReducerInterface } from './interface';

export function reducer(
  state: ReducerInterface = {
    main_menu: false,
    main_menu_expand: false,
    user_menu: false
  },
  action: any
): ReducerInterface {
  switch (action.type) {
    case STORE_MAIN: {
      state.main_menu = !state.main_menu;
      return { ...state };
    }

    case STORE_MAIN_EXPAND_SHRINK: {
      state.main_menu_expand = !state.main_menu_expand;
      return { ...state };
    }

    case STORE_USER: {
      state.user_menu = !state.user_menu;
      return { ...state };
    }
    default: {
      return state;
    }
  }
}
