import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ModuleInterface } from '@modules/app/state/interface';
import { TOGGLE } from '@modules/app/state/global-search/actions';

@Injectable({
  providedIn: 'root'
})
export class GlobalSearchService {
  constructor(private store: Store<ModuleInterface>) {}

  toggleAdvancedSearch(): void {
    this.store.dispatch({
      type: TOGGLE
    });
  }
}
