export const INIT = '(AUTO) INIT User Theme Options';

export const UPDATE = '(AUTO) UPDATE User Theme Options';

export const CLEAR = '(AUTO) CLEAR User Theme Options';

export const GET_LOCAL = '(AUTO) GET LOCALSTORAGE User Theme Data';

export const LOCALSTORAGE = '(AUTO) LOCALSTORAGE User Theme Data';

export const IMPLEMENT = '(AUTO) IMPLEMENT User Theme Data';

export const STORE = '(AUTO) STORE User Theme Data';
