import { RouterStateSnapshot } from '@angular/router';
import { RouterStateSerializer } from '@ngrx/router-store';
import { ReducerInterface } from './interface';
/**
 * Reducer function for Router Store
 */
export class CustomRouter implements RouterStateSerializer<ReducerInterface> {
  /**
   * The "serialize" function
   * Stores router information to be used by breadcrumbs
   */
  serialize(routerState: RouterStateSnapshot): ReducerInterface {
    let route = routerState.root;
    let title;
    while (route.firstChild) {
      route = route.firstChild;
      title = route.data.title;
    }
    title = title || 'UNKNOWN';
    const {
      url,
      root: { queryParams }
    } = routerState;
    const { params } = route;
    return { title, url, params, queryParams };
  }
}
