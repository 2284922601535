// import { version } from '../../package.json';
export const environment = {
  production: true,
  version: `0.0.1`,
  restful: {
    dashboard: `http://somekindofaurl/12345/api/dashboard`,
    filing: `http://somekindofaurl/12345/api/filing`,
    company: `http://somekindofaurl/12345/api/company`
  }
};
