<mat-card fxFlexFill fxLayout="column">
  <mat-card-header>
    <mat-icon
      mat-card-avatar
      aria-hidden="false"
      aria-label="Error"
      color="error"
      class="icon"
      >error</mat-icon
    >

    <mat-card-title>
      <span>An Error has occured</span>
    </mat-card-title>
    <mat-card-subtitle>
      <span>We are sorry about that.</span>
    </mat-card-subtitle>
  </mat-card-header>
  <mat-card-content fxFlex="90%">
    <p>
      While we here at <span class="bold">The Rufus</span> strive to deliver you
      the very best experience we can, sometimes things like this can happen.
    </p>
    <p>
      We are working to resolve this issue, but please let us know what
      happened, or didn't happen while you are using
      <span class="bold">The Rufus</span> application.
    </p>
    <p>
      Again we truly apologize for this inconvenience, and hope you will
      continue to use <span class="bold">The Rufus</span> application for all of
      your filing needs.
    </p>
  </mat-card-content>
  <mat-card-actions>
    <button mat-button>LIKE</button>
    <button mat-button>SHARE</button>
  </mat-card-actions>
</mat-card>
