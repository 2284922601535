<section>
  <mat-toolbar color="primary">
    <mat-toolbar-row fxLayoutAlign="start center" fxFlex="100%">
      <button mat-icon-button (click)="userMenuService.closeMenu()">
        <mat-icon aria-label="Main Menu Button">close</mat-icon>
      </button>
      <span>User Menu</span>
    </mat-toolbar-row>
  </mat-toolbar>
</section>
