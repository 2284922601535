<ng-container
  *ngIf="{
    loading: loading | async,
    menu: menuObservable | async
  } as observables"
>
  <section *ngIf="observables.loading == false; else loading" fxLayout="column">
    <mat-sidenav-container
      hasBackdrop="false"
      autosize="true"
      fxFlex="0 0 calc(100vh - 128px)"
    >
      <mat-sidenav
        [opened]="observables.menu?.main_menu"
        [ngClass]="{
          'expanded-menu': observables.menu?.main_menu_expand,
          'shrink-menu': !observables.menu?.main_menu_expand
        }"
        mode="side"
        position="start"
        autoFocus="false"
        measureScrollOffset="100"
        fxLayout="column"
        fxFlex="60px;"
        fxFlexFill
      >
        <app-main-menu></app-main-menu>
      </mat-sidenav>

      <mat-sidenav
        [opened]="observables.menu?.user_menu"
        mode="side"
        position="end"
        autoFocus="false"
        measureScrollOffset="100"
        fxLayout="column"
        fxFlex="25%"
        fxFlex.xs="100%"
        fxFlex.sm="100%"
        fxFlex.md="50%"
        fxFlex.lg="25%"
        fxFlex.xl="25%"
        fxFlexFill
      >
        <app-user-menu></app-user-menu>
      </mat-sidenav>

      <mat-sidenav-content fxLayout="column" class="width-100">
        <main fxFlexFill fxFlex="0 0 calc(100vh - 128px)">
          <router-outlet fxFlexFill></router-outlet>
        </main>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </section>
  <ng-template #loading>
    <app-loading></app-loading>
  </ng-template>
</ng-container>
