import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { BreadcrumbService } from '../../services/breadcrumb/breadcrumb.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BreadcrumbComponent implements OnInit {
  constructor(public breadcrumbService: BreadcrumbService) {}

  ngOnInit(): void {
    this.breadcrumbService.beginObserver();
  }
}
