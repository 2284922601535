import { INIT, STORE } from './actions';

import { ReducerInterface } from './interface';

export function reducer(state: ReducerInterface = {}, action: any): any {
  switch (action.type) {
    case INIT: {
      return null;
    }

    case STORE: {
      return { ...action.result };
    }

    default: {
      return state;
    }
  }
}
