import {
  getSupportedInputTypes,
  Platform,
  supportsPassiveEventListeners,
  supportsScrollBehavior
} from '@angular/cdk/platform';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ModuleInterface } from '@modules/app/state/interface';
import { STORE } from '@modules/app/state/platform/actions';
/**
 * The Platform Service
 * @stores users browser information on load of application
 */
@Injectable({
  providedIn: 'root'
})
export class PlatformService {
  /**
   * The "constructor" function
   * @params Platform
   * @params Store
   */
  constructor(
    public platform: Platform,
    private store: Store<ModuleInterface>
  ) {}
  /**
   * The "getConfiguration" function
   * Sets Browser specific information based on user
   * @params void
   * @returns void
   */
  getConfiguration(): void {
    const values = Array.from(Object.values(this.platform));
    let browserInfo = Object.keys(this.platform).reduce(
      (acc: any, current: string, index: number) => {
        if (
          values[index] &&
          current !== '_platformId' &&
          current !== 'isBrowser'
        ) {
          acc[current.toLowerCase()] = values[index];
        }
        return acc;
      },
      {}
    );
    browserInfo = Object.keys(browserInfo)[0] || null;

    const platformInfo = {
      input_types: Array.from(getSupportedInputTypes()),
      passive_event_listeners: supportsPassiveEventListeners(),
      scroll_behavior: supportsScrollBehavior(),
      is_browser: this.platform.isBrowser,
      browser: browserInfo
    };
    this.store.dispatch({ type: STORE, result: platformInfo });
  }
}
