export const MainMenuConstant = [
  {
    label: `Dashboard`,
    icon: `grid_view`,
    link: `/dashboard`
  },
  {
    label: `Company`,
    icon: `business`,
    children: [
      {
        label: `Dashboard`,
        link: `/company`
      },
      {
        label: `See All`,
        link: `/company/all`
      }
    ]
  },
  {
    label: `Filing`,
    icon: `assessment`,
    children: [
      {
        label: `Dashboard`,
        link: `/filing`
      },
      {
        label: `See All`,
        link: `/filing/all`
      }
    ]
  },
  {
    label: `Users`,
    icon: `group`,
    children: [
      {
        label: `Create New`,
        link: `/user/new`
      },
      {
        label: `See All`,
        link: `/user/`
      },
      {
        label: `My Info`,
        link: `/user/me`
      }
    ]
  },

  {
    label: `FAQs`,
    icon: `help`,
    link: `/faq`
  },
  {
    label: `Contact Support`,
    icon: `contact_mail`,
    link: `/support`
  }
];
