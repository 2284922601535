import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  OnDestroy
} from '@angular/core';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router
} from '@angular/router';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

import { ModuleInterface } from '@modules/app/state/interface';
import { ReducerInterface } from '@modules/app/state/menu/interface';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContentComponent implements OnInit, OnDestroy {
  public menuObservable: Observable<ReducerInterface>;
  public loading: BehaviorSubject<Boolean> = new BehaviorSubject(
    new Boolean(true)
  );
  private subscriptions: Array<Subscription> = [];

  constructor(private store: Store<ModuleInterface>, private router: Router) {
    this.menuObservable = this.store.select(states => {
      return states.app.menu;
    });
  }

  ngOnInit(): void {
    this.beginSubscriptions();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  beginSubscriptions(): void {
    this.subscriptions.push(
      this.router.events.subscribe((event: any) => {
        if (event instanceof NavigationStart) {
          this.loading.next(new Boolean(true));
        }
        if (event instanceof NavigationEnd) {
          this.loading.next(new Boolean(false));
        }

        // Set loading state to false in both of the below events to hide the spinner in case a request fails
        if (event instanceof NavigationCancel) {
          this.loading.next(new Boolean(false));
        }
        if (event instanceof NavigationError) {
          this.loading.next(new Boolean(false));
        }
      })
    );
  }
}
