import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { GlobalSearchService } from '../../services/global-search/global-search.service';

@Component({
  selector: 'app-global-search',
  templateUrl: './global-search.component.html',
  styleUrls: ['./global-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GlobalSearchComponent implements OnInit {
  public globalSearch: FormGroup;

  constructor(
    public globalSearchService: GlobalSearchService,
    private formBuilder: FormBuilder
  ) {
    this.globalSearch = this.formBuilder.group({
      query: ['']
    });
  }

  ngOnInit(): void {}

  onSubmit(): void {
    console.log(this.globalSearch.value);
  }
}
