import { STORE } from './actions';

import { ReducerInterface } from './interface';
/**
 * Reducer function for Responsive Store
 */
export function reducer(
  state: ReducerInterface = {
    input_types: null,
    browser: null,
    is_browser: null,
    passive_event_listener: null,
    scroll_behavior: null
  },
  action: any
): any {
  switch (action.type) {
    case STORE: {
      if (action.result && Object.keys(action.result).length > 0) {
        state = Object.assign({}, action.result);
      }
      return { ...state };
    }
    default: {
      return state;
    }
  }
}
