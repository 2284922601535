export const ThemeConstant = [
  {
    primary: '#673AB7',
    accent: '#FFC107',
    displayName: 'Deep Purple & Amber',
    name: 'deeppurple-amber',
    isDark: false,
    href: 'assets/themes/deeppurple-amber.css',
    specificStyles: {
      '.mat-primary-bg': {
        background: '#673ab7',
        color: '#ffd740'
      },
      '.mat-accent-bg': {
        background: '#ffd740',
        color: '#673ab7'
      },
      '.mat-warn-bg': {
        background: '#f44336'
      }
    },
    backgroundColors: [
      `rgba(103, 58, 183,1)`,
      `rgba(255, 215, 64, 1)`,
      `rgba(244, 67, 54, 1)`,
      `rgba(146, 201, 177,1)`,
      `rgba(237, 210, 224,1)`,
      `rgba(50, 83, 61,1)`
    ],
    borderColors: [
      `rgba(103, 58, 183, 0.2)`,
      `rgba(255, 215, 64, 0.2)`,
      `rgba(244, 67, 54, 0.2)`,
      `rgba(146, 201, 177,0.2)`,
      `rgba(237, 210, 224,0.2)`,
      `rgba(50, 83, 61,0.2)`
    ],
    font: `#fff`,
    theme: [`#673AB7`, `#F0521F`, `#5C07F0`, `#F0E61F`, `#13F0BA`]
  },
  {
    primary: '#3F51B5',
    accent: '#E91E63',
    displayName: 'Indigo & Pink',
    name: 'indigo-pink',
    isDark: false,
    isDefault: true,
    href: 'assets/themes/indigo-pink.css',
    specificStyles: {
      '.mat-primary-bg': {
        background: '#3f51b5',
        color: '#fff'
      },
      '.mat-accent-bg': {
        background: '#ff4081',
        color: 'rgba(0,0,0,.87)'
      },
      '.mat-warn-bg': {
        background: '#f44336'
      }
    },
    backgroundColors: [
      `rgba(63, 81, 181, 1)`,
      `rgba(255, 64, 129, 1)`,

      `rgba(114, 221, 247)`,
      `rgba(220, 204, 163)`,
      `rgba(159, 164, 196)`,
      `rgba(244, 67, 54 1)`
    ],
    borderColors: [
      `rgba(63, 81, 181, 0.2)`,
      `rgba(255, 64, 129, 0.2)`,

      `rgba(114, 221, 247)`,
      `rgba(220, 204, 163)`,
      `rgba(159, 164, 196)`,
      `rgba(244, 67, 54, 0.2)`
    ],
    font: `#fff`,
    theme: [`#3F51B5`, `#F01F38`, `#072AF0`, `#F0BE1F`, `#13F043`]
  },
  {
    primary: '#E91E63',
    accent: '#607D8B',
    displayName: 'Pink & Blue-grey',
    name: 'pink-bluegrey',
    isDark: true,
    href: 'assets/themes/pink-bluegrey.css',
    specificStyles: {
      '.mat-primary-bg': {
        background: '#c2185b',
        color: '#b0bec5'
      },
      '.mat-accent-bg': {
        background: '#b0bec5',
        color: '#c2185b'
      },
      '.mat-warn-bg': {
        'background-color': '#f44336'
      }
    },
    backgroundColors: [
      `rgba(194, 24, 91, 1)`,
      `rgba(176, 190, 197, 1)`,
      `rgba(244, 67, 54, 1)`,
      `rgba(142, 166, 4, 1)`,
      `rgba(59, 82, 73, 1)`,
      `rgba(73, 59, 42, 1)`
    ],
    borderColors: [
      `rgba(194, 24, 91, 0.2)`,
      `rgba(176, 190, 197, 0.2)`,
      `rgba(244, 67, 54, 0.2)`,
      `rgba( 142, 166, 4, 0.2)`,
      `rgba( 59, 82, 73, 0.2)`,
      `rgba( 73, 59, 42, 0.2)`
    ],
    font: `#fff`,
    theme: [`#E91E63`, `#F0B51F`, `#F00755`, `#1FF033`, `#134DF0`]
  },
  {
    primary: '#9C27B0',
    accent: '#4CAF50',
    displayName: 'Purple & Green',
    name: 'purple-green',
    isDark: true,
    href: 'assets/themes/purple-green.css',
    specificStyles: {
      '.mat-primary-bg': {
        background: '#7b1fa2',
        color: '#69f0ae'
      },
      '.mat-accent-bg': {
        background: '#69f0ae',
        color: '#7b1fa2'
      },
      '.mat-warn-bg': {
        background: '#f44336'
      }
    },
    backgroundColors: [
      `rgba(123, 31, 162, 1)`,
      `rgba(105, 240, 174, 1)`,
      `rgba(244, 67, 54, 1)`,
      `rgba(178, 152, 220, 1)`,
      `rgba(184, 208, 235, 1)`,
      `rgba(242, 175, 41, 1)`
    ],
    borderColors: [
      `rgba(123, 31, 162, 0.2)`,
      `rgba(105, 240, 174, 0.2)`,
      `rgba(244, 67, 54, 0.2)`,
      `rgba( 178, 152, 220, 0.2)`,
      `rgba( 184, 208, 235, 0.2)`,
      `rgba(242, 175, 41, 0.2)`
    ],
    font: `#fff`,
    theme: [`#9C27B0`, `#F0881F`, `#CD07F0`, `#90F01F`, `#13BBF0`]
  }
];
