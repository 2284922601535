import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { environment } from '@env/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent implements OnInit {
  public version = environment.version;
  public currentYear!: number;
  constructor() {}

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();
  }
}
