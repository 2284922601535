import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ModuleInterface } from '../../state/interface';
import { STORE_USER } from '../../state/menu/actions';

@Injectable({
  providedIn: 'root'
})
export class UserMenuService {
  constructor(private store: Store<ModuleInterface>) {}

  closeMenu(): void {
    this.store.dispatch({
      type: STORE_USER
    });
  }

  trackByFn(index: any, item: any): any {
    return index;
  }
}
