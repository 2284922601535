import { TOGGLE } from './actions';

import { ReducerInterface } from './interface';

export function reducer(
  state: ReducerInterface = {
    advanced_search: false
  },
  action: any
): ReducerInterface {
  switch (action.type) {
    case TOGGLE: {
      state.advanced_search = !state.advanced_search;
      return { ...state };
    }

    default: {
      return state;
    }
  }
}
