<ng-container *ngIf="menuObservable | async; let menuObservableAsync">
  <nav>
    <mat-nav-list>
      <ng-container *ngFor="let menu of mainMenu">
        <a
          *ngIf="menu.link || !menu.children"
          mat-list-item
          [routerLink]="[menu.link]"
          (click)="mainMenuService.closeMenu()"
          routerLinkActive="mat-active"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <mat-icon
            class="margin-right-1rem"
            matTooltip="Link to {{ menu.label }}"
            matTooltipPosition="right"
            >{{ menu.icon }}</mat-icon
          >

          <span *ngIf="menuObservableAsync.main_menu_expand">
            {{ menu.label }}
          </span>
        </a>

        <mat-accordion
          *ngIf="!menu.link && menu.children"
          [hideToggle]="!menuObservableAsync.main_menu_expand"
        >
          <mat-expansion-panel
            (click)="expandIfNeeded(menuObservableAsync.main_menu_expand)"
            class="mat-elevation-z"
            [expanded]="menuObservableAsync.main_menu_expand"
          >
            <mat-expansion-panel-header
              [ngClass]="{
                'padding-right-0': !menuObservableAsync.main_menu_expand
              }"
            >
              <mat-panel-title>
                <mat-list-item
                  fxLayout="row"
                  fxLayoutAlign="space-between center"
                  fxFlexFil
                >
                  <mat-icon
                    class="margin-right-1rem"
                    matTooltip="Link to {{ menu.label }}"
                    matTooltipPosition="right"
                    >{{ menu.icon }}</mat-icon
                  >
                  <span *ngIf="menuObservableAsync.main_menu_expand">
                    {{ menu.label }}
                  </span>
                </mat-list-item>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <ng-container
              *ngFor="
                let child of menu.children;
                trackBy: mainMenuService.trackByFn
              "
            >
              <a
                mat-list-item
                [routerLink]="[child.link]"
                (click)="mainMenuService.closeMenu()"
                aria-label="Dashboard Link"
                routerLinkActive="mat-primary-bg"
                [routerLinkActiveOptions]="{ exact: true }"
                >{{ child.label }}</a
              >
            </ng-container>
          </mat-expansion-panel>
        </mat-accordion>
      </ng-container>

      <a
        mat-list-item
        (click)="mainMenuService.expandMenu()"
        class="expand-shrink-action"
      >
        <mat-icon *ngIf="!menuObservableAsync.main_menu_expand"
          >keyboard_arrow_right</mat-icon
        >
        <mat-icon *ngIf="menuObservableAsync.main_menu_expand"
          >keyboard_arrow_left</mat-icon
        >
      </a>
    </mat-nav-list>
  </nav>
</ng-container>
