import {
  ActionReducer,
  ActionReducerMap,
  combineReducers,
  compose
} from '@ngrx/store';

// associated reducers with this module
import { reducer as reducer1 } from './platform';
import { reducer as reducer2 } from './responsive';
import { reducer as reducer3 } from './menu';
import { reducer as reducer4 } from './user-list';
import { reducer as reducer5 } from './theme';
import { reducer as reducer6 } from './global-search';

const reducersMap: ActionReducerMap<any> = {
  platform: reducer1,
  responsive: reducer2,
  menu: reducer3,
  user_list: reducer4,
  theme: reducer5,
  global_search: reducer6
};

const reducer: ActionReducer<any> = compose(combineReducers)(reducersMap);
/**
 * Module Reducers associated with this module
 */
export function ModuleReducers(state: any, action: any): any {
  return reducer(state, action);
}
