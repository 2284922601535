<button
  mat-icon-button
  [matMenuTriggerFor]="menu"
  aria-label="Toggle the Breadcrumbs menu"
>
  <mat-icon>link</mat-icon>
</button>
<mat-menu #menu="matMenu">
  <ng-container
    *ngFor="let current of breadcrumbService.getBreadcrumbs(); let i = index"
  >
    <button mat-menu-item [routerLink]="[current['url']]">
      {{ current['title'] }}
    </button>
  </ng-container>
</mat-menu>
