import { STORE } from './actions';

import { ReducerInterface } from './interface';
/**
 * Reducer function for Platform Store
 */
export function reducer(
  state: ReducerInterface = { media_query: null, alias: null, suffix: null },
  action: any
): any {
  switch (action.type) {
    case STORE: {
      if (action.result && Object.keys(action.result).length > 0) {
        state = Object.assign({}, action.result);
      }
      return { ...state };
    }
    default: {
      return state;
    }
  }
}
