<ng-container *ngIf="searchObservable | async; let searchObservableAsync">
  <mat-accordion class="advanced-search-container">
    <mat-expansion-panel [expanded]="searchObservableAsync.advanced_search">
      <mat-card>
        <mat-card-header>
          <mat-card-title>Advanced Search</mat-card-title>
          <mat-card-subtitle>
            Use as many or as little of these advanced searches as necessary
          </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content fxLayout="row" fxLayoutAlign="space-around start">
          <div
            fxLayout="column"
            fxLayoutAlign="space-around start"
            fxFlex="33%"
            class="mat-elevation-z1"
          >
            <h2 class="mat-h2">Filing</h2>
            <p>Input here...</p>
            <p>Input here...</p>
            <p>Input here...</p>
            <p>Input here...</p>
            <p>Input here...</p>
            <p>Input here...</p>
          </div>
          <div
            fxLayout="column"
            fxLayoutAlign="space-around start"
            fxFlex="33%"
            class="mat-elevation-z1"
          >
            <h2 class="mat-h2">Company</h2>
            <p>Input here...</p>
            <p>Input here...</p>
            <p>Input here...</p>
            <p>Input here...</p>
            <p>Input here...</p>
            <p>Input here...</p>
          </div>
          <div
            fxLayout="column"
            fxLayoutAlign="space-around start"
            fxFlex="33%"
            class="mat-elevation-z1"
          >
            <h2 class="mat-h2">Fact</h2>
            <p>Input here...</p>
            <p>Input here...</p>
            <p>Input here...</p>
            <p>Input here...</p>
            <p>Input here...</p>
            <p>Input here...</p>
          </div>
        </mat-card-content>
        <mat-card-actions fxLayout="row" fxLayoutAlign="end center">
          <button mat-raised-button>Clear</button>
          <button mat-raised-button color="primary">Submit</button>
        </mat-card-actions>
      </mat-card>
    </mat-expansion-panel>
  </mat-accordion>
</ng-container>
