import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

import { HeaderService } from '@modules/app/services/header/header.service';
import { GlobalSearchService } from '@modules/app/services/global-search/global-search.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit {
  constructor(
    public headerService: HeaderService,
    public globalSearchService: GlobalSearchService,
    ) {}

  ngOnInit(): void {}
}
