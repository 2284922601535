import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';

import { ErrorComponent } from '@modules/ui/components/error/error.component';
import { ModuleInitResolver } from '@modules/app/services/module-init/module-init.resolver';

const routerOptions: ExtraOptions = {
  useHash: false,
  anchorScrolling: 'enabled'
};
const routes: Routes = [
  {
    path: 'dashboard',
    resolve: {
      content: ModuleInitResolver
    },
    loadChildren: () =>
      import('src/modules/dashboard/dashboard.module').then(
        m => m.DashboardModule
      )
  },
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: 'filing',
    resolve: {
      content: ModuleInitResolver
    },
    loadChildren: () =>
      import('src/modules/filing/filing.module').then(m => m.FilingModule)
  },
  {
    path: 'company',
    resolve: {
      content: ModuleInitResolver
    },
    loadChildren: () =>
      import('src/modules/company/company.module').then(m => m.CompanyModule)
  },
  {
    path: 'error',
    component: ErrorComponent,
    data: {
      title: 'Error'
    }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
