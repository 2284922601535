import { PlatformService } from '@modules/app/services/platform/platform.service';
/**
 * The Platform Factory
 * @returns configuration from Platform Service
 */
export function PlatformFactory(Platform: PlatformService): any {
  return () => {
    Platform.getConfiguration();
  };
}
