import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { withLatestFrom, switchMap } from 'rxjs/operators';

import { Store, select } from '@ngrx/store';
import { ThemeConstant } from '@modules/app/constants/theme.constant';

import { ModuleInterface } from '@modules/app/state/interface';

import {
  INIT,
  UPDATE,
  GET_LOCAL,
  LOCALSTORAGE,
  IMPLEMENT,
  STORE
} from '@modules/app/state/theme/actions';

import { of } from 'rxjs';

import { ThemeService } from '@modules/app/services/theme/theme.service';
@Injectable()
export class Effects {
  constructor(
    private actions: Actions,
    private store: Store<ModuleInterface>,
    private themeService: ThemeService
  ) {}

  initAction = createEffect(() =>
    this.actions.pipe(
      ofType(INIT),
      switchMap((action: any) => {
        return [
          {
            type: GET_LOCAL
          }
        ];
      })
    )
  );

  getLocalAction = createEffect(() =>
    this.actions.pipe(
      ofType(GET_LOCAL),
      switchMap((action: any) => {
        const storedTheme = localStorage.getItem('theme');
        let currentTheme;
        if (storedTheme) {
          currentTheme = JSON.parse(storedTheme);
        } else {
          currentTheme = ThemeConstant[0];

          localStorage.setItem('theme', JSON.stringify(currentTheme));
        }
        return [
          {
            type: IMPLEMENT,
            result: currentTheme
          },
          {
            type: STORE,
            result: currentTheme
          }
        ];
      })
    )
  );

  updateAction = createEffect(() =>
    this.actions.pipe(
      ofType(UPDATE),
      switchMap((action: any) => {
        const newStore = ThemeConstant.find((element: { name: any }) => {
          return element.name === action.results.name;
        });
        return [
          {
            type: LOCALSTORAGE,
            result: newStore
          }
        ];
      })
    )
  );

  localStorageAction = createEffect(() =>
    this.actions.pipe(
      ofType(LOCALSTORAGE),
      switchMap((action: any) =>
        of(action).pipe(
          withLatestFrom(this.store.pipe(select(states => states.app.theme))),
          switchMap(([storeAction, store]) => {
            localStorage.setItem('theme', JSON.stringify(storeAction.result));
            return [
              {
                type: IMPLEMENT,
                result: storeAction.result
              },
              {
                type: STORE,
                result: storeAction.result
              }
            ];
          })
        )
      )
    )
  );

  implementAction = createEffect(() =>
    this.actions.pipe(
      ofType(IMPLEMENT),
      switchMap((action: any) => {
        this.themeService.setConfiguration(action.result);
        return [
          {
            type: STORE,
            result: action.result
          }
        ];
      })
    )
  );
}
