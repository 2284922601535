import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ModuleInterface } from '@modules/app/state/interface';
import { STORE_MAIN, STORE_USER } from '@modules/app/state/menu/actions';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {
  constructor(private store: Store<ModuleInterface>) {}

  toggleMainMenu(): void {
    this.store.dispatch({
      type: STORE_MAIN
    });
  }

  toggleUserMenu(): void {
    this.store.dispatch({
      type: STORE_USER
    });
  }
}
