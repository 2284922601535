import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { ReducerInterface } from '@modules/app/state/theme/interface';
import { ModuleInterface } from '@modules/app/state/interface';
import { INIT } from '@modules/app/state/theme/actions';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  public themeObservable: Observable<ReducerInterface>;

  constructor(private store: Store<ModuleInterface>) {
    this.themeObservable = store.select(states => {
      return states.app.theme;
    });
  }

  getConfiguration(): void {
    this.store.dispatch({
      type: INIT
    });
  }

  setConfiguration(config: ReducerInterface): void {
    if (Object.keys(config).length > 0) {
      if (document.getElementById(`simple-theme-classes`)) {
        document.getElementById(`simple-theme-classes`)?.remove();
      }

      const styleTag = document.createElement('style');
      styleTag.id = `simple-theme-classes`;

      document
        .getElementById('style-manager-theme')
        ?.setAttribute('href', config.href);
      const specificStyles = config.specificStyles;

      let cssString = ``;
      for (const key in specificStyles) {
        if (key) {
          cssString += ` ${key} {`;
          for (const nestedKey in specificStyles[key]) {
            if (nestedKey) {
              cssString += `${nestedKey} : ${specificStyles[key][nestedKey]};`;
            }
          }
          cssString += `}`;
        }
      }

      styleTag.appendChild(document.createTextNode(cssString));

      document.getElementsByTagName('head')[0].appendChild(styleTag);
      // add CSS variables to the ROOT of the application
      const root = document.documentElement;
      root.style.setProperty(`--primary`, config.primary);
      root.style.setProperty(`--accent`, config.accent);
      root.style.setProperty(`--font`, config.font);
    }
  }
}
