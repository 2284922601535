import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { MainMenuService } from '@modules/app/services/main-menu/main-menu.service';
import { MainMenuConstant } from '@modules/app/constants/main-menu.constant';
import { ModuleInterface } from '@modules/app/state/interface';
import { ReducerInterface } from '@modules/app/state/menu/interface';

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MainMenuComponent implements OnInit {
  public mainMenu: Array<any> = MainMenuConstant;
  public menuObservable: Observable<ReducerInterface>;

  constructor(
    public mainMenuService: MainMenuService,
    private store: Store<ModuleInterface>
  ) {
    this.menuObservable = this.store.select(states => {
      return states.app.menu;
    });
  }
  ngOnInit(): void {}

  expandIfNeeded(input: boolean): void {
    if (!input) {
      this.mainMenuService.expandMenu();
    }
  }
}
