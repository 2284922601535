import { Injectable } from '@angular/core';
import {
  Router,
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { RestfulService } from '@modules/app/services/restful/restful.service';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ModuleInitResolver implements Resolve<boolean> {
  constructor(private restfulService: RestfulService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    switch (state.url) {
      case `/`: {
        return this.restfulService.getDashboard().pipe(
          catchError((error: any) => {
            console.log(error);
            return of(null);
          })
        );
      }
      case `/filing`: {
        return this.restfulService.getDashboard();
      }
      case `/company`: {
        return this.restfulService.getCompanies();
      }
      default: {
        return this.restfulService.getDashboard();
        // return this.restfulService.getUserList();
      }
    }
  }
}
