import { ResponsiveService } from '@modules/app/services/responsive/responsive.service';
/**
 * The Responsive Factory
 * @returns configuration from Responsive Service
 */
export function ResponsiveFactory(Platform: ResponsiveService): any {
  return () => {
    Platform.getConfiguration();
  };
}
