import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { StoreModule, ActionReducer, MetaReducer } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule, routerReducer } from '@ngrx/router-store';

import { AppRoutingModule } from './app-routing.module';
import { UiModule } from '@modules/ui/ui.module';

import { ModuleReducers } from '@modules/app/state/';
import { ModuleEffects } from '@modules/app/state/effects';
import { CustomRouter } from '@modules/app/state/router/';

import { ResponsiveService } from '@modules/app/services/responsive/responsive.service';
import { ResponsiveFactory } from '@modules/app/factories/responsive.factory';

import { PlatformService } from '@modules/app/services/platform/platform.service';
import { PlatformFactory } from '@modules/app/factories/platform.factory';

import { ThemeService } from '@modules/app/services/theme/theme.service';
import { ThemeFactory } from '@modules/app/factories/theme.factory';

import { environment } from 'src/environments/environment';

import { RootComponent } from './components/root/root.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { ContentComponent } from './components/content/content.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { MainMenuComponent } from './components/main-menu/main-menu.component';
import { UserMenuComponent } from './components/user-menu/user-menu.component';
import { UserListComponent } from './components/user-list/user-list.component';
import { ThemeComponent } from './components/theme/theme.component';
import { GlobalSearchComponent } from './components/global-search/global-search.component';
import { AdvancedGlobalSearchComponent } from './components/advanced-global-search/advanced-global-search.component';

/**
 * Function "debugState" for NGRX StoreDevtoolsModule
 */
export function debugState(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state, action): ActionReducer<any> => {
    const result = reducer(state, action);
    return result;
  };
}
/**
 * Setup for NGRX StoreDevtoolsModule
 */
export const metaReducers: MetaReducer<any>[] = !environment.production
  ? [debugState]
  : [];
/**
 * App Routing Module
 */
@NgModule({
  declarations: [
    RootComponent,
    HeaderComponent,
    FooterComponent,
    ContentComponent,
    BreadcrumbComponent,
    MainMenuComponent,
    UserMenuComponent,
    UserListComponent,
    ThemeComponent,
    GlobalSearchComponent,
    AdvancedGlobalSearchComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    StoreModule.forRoot(
      { router: routerReducer },
      {
        metaReducers,
        runtimeChecks: {
          strictStateSerializability: false,
          strictActionSerializability: false,
          strictStateImmutability: false,
          strictActionImmutability: false,
          strictActionWithinNgZone: false,
          strictActionTypeUniqueness: false
        }
      }
    ),
    StoreModule.forFeature('app', ModuleReducers),
    StoreRouterConnectingModule.forRoot({
      serializer: CustomRouter,
      stateKey: 'router'
    }),

    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
      name: 'The Rufus'
    }),

    EffectsModule.forRoot(ModuleEffects),

    UiModule
  ],
  providers: [
    ResponsiveService,
    PlatformService,
    ThemeService,
    {
      provide: APP_INITIALIZER,
      useFactory: ResponsiveFactory,
      deps: [ResponsiveService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: PlatformFactory,
      deps: [PlatformService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: ThemeFactory,
      deps: [ThemeService],
      multi: true
    }
  ],
  bootstrap: [RootComponent]
})
export class AppModule {}
