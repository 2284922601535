<header>
  <mat-toolbar
    color="primary"
    class="header-toolbar"
    fxLayout="row"
    fxLayoutAlign="space-between center"
  >
    <div>
      <button
        mat-icon-button
        class="menu-icon"
        aria-label="Main Menu Button"
        (click)="headerService.toggleMainMenu()"
      >
        <mat-icon>menu</mat-icon>
      </button>

      <a href="/dashboard" mat-button class="logo-image" aria-label="Home Link">
        <mat-icon class="logo-image">pets</mat-icon>
        <span data-test="header-title" fxHide.lt-sm>The Rufus</span>
      </a>
    </div>

    <!-- Global Search-->
    <app-global-search fxFlex="30" fxHide.lt-sm></app-global-search>
    <!-- END Global Search -->

    <div>
      <button
        fxHide.gt-xs
        mat-icon-button
        aria-label="Main Menu Button"
        (click)="globalSearchService.toggleAdvancedSearch()"
      >
        <mat-icon>search</mat-icon>
      </button>
      <!-- Breadcrumb -->
      <app-breadcrumb></app-breadcrumb>
      <!-- END Breadcrumb -->

      <!-- Theme -->
      <app-theme></app-theme>
      <!-- END Theme -->

      <button
        mat-icon-button
        aria-label="Example icon-button with heart icon"
        (click)="headerService.toggleUserMenu()"
      >
        <mat-icon>person</mat-icon>
      </button>
    </div>
  </mat-toolbar>
  <app-advanced-global-search></app-advanced-global-search>
</header>
