import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpParams,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RestfulService {
  constructor(private httpClient: HttpClient, private router: Router) {}

  handleError(error: HttpErrorResponse): Observable<any> {
    console.log('so we here');
    let errorMessage;
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `${error.message}`;
    } else {
      // Server-side errors
      errorMessage = `${error.message}`;
    }
    return throwError(errorMessage);
  }

  getDashboard(): Observable<any> {
    const params = new HttpParams();

    return this.httpClient.get(environment.restful.dashboard, { params }).pipe(
      catchError((error: any) => {
        console.log(error);
        // this.router.navigateByUrl('/error');
        return of(null);
      })
    );
  }

  getFilings(): Observable<any> {
    const params = new HttpParams();

    return this.httpClient
      .get(environment.restful.filing, { params })
      .pipe(catchError(this.handleError));
  }

  getCompanies(): Observable<any> {
    const params = new HttpParams();

    return this.httpClient
      .get(environment.restful.company, { params })
      .pipe(catchError(this.handleError));
  }
}
