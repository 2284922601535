import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { ModuleInterface } from '@modules/app/state/interface';
import { ReducerInterface } from '@modules/app/state/global-search/interface';
@Component({
  selector: 'app-advanced-global-search',
  templateUrl: './advanced-global-search.component.html',
  styleUrls: ['./advanced-global-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdvancedGlobalSearchComponent implements OnInit {
  public searchObservable: Observable<ReducerInterface>;

  constructor(private store: Store<ModuleInterface>) {
    this.searchObservable = this.store.select(states => {
      return states.app.global_search;
    });
  }

  ngOnInit(): void { }
}
