import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ModuleInterface } from '../../state/interface';
import { STORE_MAIN, STORE_MAIN_EXPAND_SHRINK } from '../../state/menu/actions';

@Injectable({
  providedIn: 'root'
})
export class MainMenuService {
  constructor(private store: Store<ModuleInterface>) {}

  closeMenu(): void {
    this.store.dispatch({
      type: STORE_MAIN
    });
  }

  expandMenu(): void {
    this.store.dispatch({
      type: STORE_MAIN_EXPAND_SHRINK
    });
  }

  trackByFn(index: any, item: any): any {
    return index;
  }
}
