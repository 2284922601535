import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import { ModuleInterface } from '@modules/app/state/interface';
import { ReducerInterface } from '@modules/app/state/router/interface';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {
  public breadcrumbObservable: Observable<any>;

  public previousCrumbs: Array<ReducerInterface> = [];

  constructor(private store: Store<ModuleInterface>) {
    this.breadcrumbObservable = this.store.select(states => {
      return states.router;
    });
  }

  beginObserver(): void {
    this.breadcrumbObservable.subscribe((options: any) => {
      if (options && options.state) {
        this.previousCrumbs.unshift(options.state);
      }
    });
  }

  getBreadcrumbs(): Array<ReducerInterface> {
    return this.previousCrumbs;
  }
}
